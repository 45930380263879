import { render, staticRenderFns } from "./Gadgetmodal.vue?vue&type=template&id=317c6854&scoped=true"
import script from "./Gadgetmodal.vue?vue&type=script&lang=js"
export * from "./Gadgetmodal.vue?vue&type=script&lang=js"
import style0 from "./Gadgetmodal.vue?vue&type=style&index=0&id=317c6854&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "317c6854",
  null
  
)

export default component.exports