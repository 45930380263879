<template>
  <section class="bg-deeparmy mt-6 lg:mt-0 py-6 px-4 lg:px-16 flex flex-col gap-14 lg:pt-12 lg:pb-12 lg:flex-row lg:justify-between lg:relative overflow-y-hidden">
      <div>
          <div class="lg:w-1/2">
            <h3 class="text-white text-2xl font-bold">Top Notch Insurance Services</h3>
            <!-- <p class="font-medium text-sm mt-6 lg:text-base" style="color: #567965">We protect your money with military-grade security and fraud systems.</p> -->
          </div>
      </div>
      <div class="flex gap-4 items-center lg:z-10">
        <!-- <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M39.8125 6.625H9.1875C8.37556 6.62592 7.59713 6.94887 7.023 7.523C6.44887 8.09713 6.12592 8.87556 6.125 9.6875V20.9166C6.125 38.0385 20.6386 43.7178 23.548 44.6824C24.1654 44.8919 24.8346 44.8919 25.452 44.6825C28.3614 43.7178 42.875 38.0385 42.875 20.9166V9.6875C42.8741 8.87556 42.5511 8.09713 41.977 7.523C41.4029 6.94887 40.6244 6.62592 39.8125 6.625ZM9.1875 9.6875H39.8125V20.9166C39.8125 24.3012 39.1628 27.175 38.1288 29.6085L25.3782 20.683C25.1208 20.5029 24.8142 20.4063 24.5 20.4063C24.1858 20.4063 23.8792 20.5029 23.6219 20.683L10.8711 29.6085C9.83723 27.175 9.1875 24.3012 9.1875 20.9166V9.6875Z" fill="#219653"/>
        </svg> -->
        <!-- <p class="text-army font-semibold text-2xl lg:w-1/2">We’re PCI-DSS Level 1 Certified.</p> -->
      </div>
      <svg class="waves absolute hidden lg:block" width="161" height="280" viewBox="0 0 161 280" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.7">
        <path d="M140.908 307.027C109.378 301.771 45.364 271.727 41.5486 193.607C36.7793 95.9554 140.908 91.7097 140.908 41.9744C140.908 2.18608 112.293 -5.73923 97.9849 -4.72835" stroke="#EFEFEF" stroke-width="1.58976"/>
        <path d="M98.3628 311.217C68.4679 301.938 12.0999 264.009 25.7877 186.535C42.8975 89.6923 146.452 99.0493 157.559 50.0415C166.446 10.8352 140.019 -0.695226 125.694 -1.55967" stroke="#EFEFEF" stroke-width="1.58976"/>
        <path d="M186.132 299.212C153.861 298.843 83.2986 279.107 59.2099 203.124C29.0991 108.144 130.045 88.1907 117.089 39.4477C106.725 0.453268 76.6159 -2.97392 62.8568 0.186782" stroke="#EFEFEF" stroke-width="1.58976"/>
        </g>
      </svg>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.waves{
  top: 0;
  right: 20%;
  opacity: 0.2
}
@media only screen and (min-width: 1024px){
    section{
        padding-left: 10%;
        padding-right: 10%;
    }
}
</style>