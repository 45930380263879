import { render, staticRenderFns } from "./Stats.vue?vue&type=template&id=324e73bf&scoped=true"
import script from "./Stats.vue?vue&type=script&lang=js"
export * from "./Stats.vue?vue&type=script&lang=js"
import style0 from "./Stats.vue?vue&type=style&index=0&id=324e73bf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324e73bf",
  null
  
)

export default component.exports