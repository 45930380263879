<template>
  <section class="mt-10 px-4">
      <div class="grid gap-6 pt-6 lg:pt-24 rounded-2xl px-4  lg:pl-20  relative lg:grid-cols-2 overflow-hidden">
            <div class="lg:pr-16">
                <h3 class="text-darker text-2xl font-bold">Pay as you go insurance you control</h3>
                <p class="font-medium text-sm mt-6 text-darker lg:text-lg">We embed our insurance product into your lifestyle and enable you have the flexibility of payment</p>
            </div>
            <div class="flex justify-end lg:justify-center">
                <img class="" src="../.././assets/images/landingpage/hand2.png" alt="">
            </div>
            <router-link to="/login" class="bg-navy rounded-md px-6 py-3 absolute bottom-4 left-4 lg:left-20 lg:bottom-10">
                <div class="flex items-center gap-2">
                    <span class="text-white text-sm font-semibold">Sign in</span>
                    <div class="mt-1 flex items-center gap-2">
                        <svg class="arrow1" width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.178146 9.16024L4.3113 5.13514L0.178146 1.08303C-0.389149 0.542749 0.529329 -0.375729 1.09662 0.164552L5.79707 4.75694C5.98617 4.94604 6.01319 5.29723 5.82409 5.48632L1.09662 10.0787C0.529329 10.619 -0.389149 9.70052 0.178146 9.16024Z" fill="white"/>
                        </svg>
                    </div>
                </div>
            </router-link>
            <svg class="hidden lg:block absolute waves -right-6 " width="534" height="231" viewBox="0 0 534 231" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.7">
                <path d="M177.306 59.8218L177.263 58.6848L177.306 59.8218ZM-34.3387 202.281C-24.5813 157.622 31.3625 66.3992 177.348 60.9587L177.263 58.6848C30.169 64.1666 -26.5984 156.194 -36.5617 201.795L-34.3387 202.281ZM177.348 60.9587C222.978 59.2582 257.64 67.2647 285.426 80.1947C313.219 93.1285 334.194 111.017 352.424 129.176C361.549 138.266 369.958 147.393 378.22 156.027C386.467 164.646 394.553 172.756 402.97 179.733C419.825 193.704 438.064 203.176 461.738 203.176V200.9C438.766 200.9 421.024 191.742 404.422 177.981C396.111 171.092 388.1 163.06 379.864 154.454C371.642 145.861 363.18 136.678 354.03 127.564C335.711 109.316 314.519 91.2235 286.386 78.1317C258.244 65.0361 223.22 56.9722 177.263 58.6848L177.348 60.9587ZM461.738 203.176C499.261 203.176 521.962 192.876 535.044 179.804C548.114 166.744 551.453 151.042 550.477 140.496L548.211 140.706C549.131 150.638 545.985 165.655 533.436 178.194C520.9 190.721 498.851 200.9 461.738 200.9V203.176Z" fill="#CCCCCC"/>
                <path d="M190.57 37.2628L190.722 36.1353L190.57 37.2628ZM-42.2558 141.57C-25.0304 99.2274 45.6411 18.8727 190.418 38.3903L190.722 36.1353C44.8454 16.4694 -26.7747 97.4767 -44.3635 140.713L-42.2558 141.57ZM190.418 38.3903C235.671 44.4909 268.461 58.2873 293.637 75.7633C318.819 93.2443 336.439 114.445 351.308 135.446C358.751 145.958 365.481 156.384 372.15 166.3C378.808 176.198 385.394 185.568 392.499 193.877C406.726 210.516 423.084 222.958 446.412 226.992L446.8 224.75C424.163 220.835 408.242 208.787 394.228 192.398C387.213 184.193 380.687 174.914 374.038 165.03C367.401 155.162 360.628 144.671 353.165 134.131C338.224 113.028 320.425 91.5888 294.934 73.894C269.436 56.1942 236.299 42.2795 190.722 36.1353L190.418 38.3903ZM446.412 226.992C483.386 233.387 507.511 227.107 522.629 216.455C537.732 205.814 543.699 190.91 544.534 180.352L542.266 180.172C541.479 190.117 535.821 204.377 521.318 214.595C506.831 224.802 483.37 231.075 446.8 224.75L446.412 226.992Z" fill="#CCCCCC"/>
                <path d="M159.453 85.1004L159.185 83.9946L159.453 85.1004ZM-19.6529 266.784C-18.9667 221.077 17.7292 120.555 159.72 86.2063L159.185 83.9946C16.1157 118.604 -21.2274 220.079 -21.9281 266.75L-19.6529 266.784ZM159.72 86.2063C204.103 75.47 239.664 76.4274 269.465 83.5767C299.275 90.7281 323.387 104.09 344.863 118.264C355.612 125.358 365.667 132.632 375.48 139.452C385.277 146.259 394.813 152.601 404.449 157.765C423.744 168.107 443.502 173.765 466.704 169.06L466.252 166.83C443.738 171.396 424.529 165.947 405.524 155.76C396.009 150.66 386.561 144.381 376.779 137.583C367.013 130.796 356.895 123.479 346.116 116.365C324.535 102.122 300.17 88.6028 269.996 81.364C239.813 74.1231 203.885 73.1816 159.185 83.9946L159.72 86.2063ZM466.704 169.06C503.478 161.602 523.679 146.995 533.902 131.584C544.115 116.187 544.267 100.134 541.213 89.9924L539.034 90.6483C541.91 100.2 541.812 115.542 532.006 130.326C522.21 145.094 502.624 159.453 466.252 166.83L466.704 169.06Z" fill="#CCCCCC"/>
                </g>
            </svg>
      </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.whitebox{
    box-shadow: 0px 15px 34px rgba(15, 34, 23, 0.1);
}
.arrow1{
    animation-name: arrow;
    animation-duration: 1s;
    animation-delay: 0;
    animation-iteration-count: infinite;
}
@keyframes arrow{
    0%{
        transform: translateX(-2px);
        opacity: 0
    }
    80%{
        transform: translateX(2px);
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: translateX(0px);
    }
}
@media only screen and (min-width: 1024px){
    h3{
        font-size: 40px;
        line-height: 39px
    }
    img{
        z-index: 100
    }
    .waves{
        top: 50%;
        transform: translateY(-50%);
    }
}
</style>